@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Content {
  padding: 60px 0;
}

.Container {
  padding: 0 60px;
}

h1.pageTitle {
  margin-top: 0;
  margin-bottom: 12px;

  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  color: #000000;
}

.pageText {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: #000000;
  text-transform: uppercase;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  padding-bottom: 50px;
  border-bottom: 1px solid;
  border-color: rgba(217, 223, 233, 0.4);
}

.pageToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 40px 16px;
  border-bottom: 1px solid;
  border-color: rgba(217, 223, 233, 0.4);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
div.MuiAccordionSummary-root {
  padding: 0;
}

.MuiAccordion-root {
  overflow: hidden;
}
.MuiAccordion-root:before {
  display: none;
}
.red-border {
  /*border: solid 1px red;*/
}
div.MuiAccordionSummary-content {
  margin: 20px 0;
}
.profile-modal input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 0 12px;
  font-weight: 500;
  font-family: "Poppins";
  box-sizing: border-box;
}
.profile-modal label {
  margin-bottom: 15px;
  font-weight: 500;
}
.profile-modal input {
  border-width: 1px;
}
.profile-modal select {
  min-width: 50%;
  font-weight: 500;
  font-family: "Poppins";
  height: 40px;
  border-radius: 4px;
  padding: 0 12px;
  box-sizing: border-box;
}
.profile-modal .color-div-wrap label {
  padding-left: 32px;
}
.profile-modal .color-div-wrap {
  padding: 0 12px;
  margin-bottom: 20px;
}
.profile-modal .color-div-wrap .color-div {
  width: 50px;
  margin-right: 27px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  border: solid 0.5px #979797;
  margin-bottom: 5px;
}
.profile-modal .color-div-wrap .color-input input {
  width: calc(100% - 27px);
}
div.MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.profile-modal select.red-border.before_save {
  border: none;
  background-color: rgba(0, 0, 0, 0.04);
}
.logo_uploader_wrap {
  margin: 0 50px;
}
.logo_uploader {
  position: relative;
  width: 188px;
  border: solid 1px rgba(217, 223, 233, 0.4);
  height: 207px;
}
.logo_uploader:not(.red-border) {
  border: solid 1px rgba(217, 223, 233, 0.4);
}
.logo_uploader img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  z-index: 2;
  background: white;
  padding: 40px;
  box-sizing: border-box;
}
.logo_uploader_wrap a {
  color: black;
  display: block;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 15px;
}
.logo_uploader .not-selected-div {
  z-index: 1;
  display: flex;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  height: 100%;
  padding: 40px;
  flex-direction: column;
  align-items: center;
}
.logo_uploader .not-selected-div span {
  color: rgba(0, 0, 0, 0.56);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.logo_uploader input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 3;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.error-message {
  color: red;
  font-weight: 500;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
}
tr.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #f1f7fe;
}
div.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.preview {
  position: relative;
  width: 100%;
  margin-top: 5px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  text-align: center;
}
.preview-content {
  width: 300px;
  position: relative;
  overflow: hidden;
  transform-origin: top left;
}
.preview img {
  width: 100%;
}
.tint-wrap {
  font-size: 0;
}
.preview .link {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}
.switch-icon {
  font-size: 16px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 7px;
}
.switch-icon svg {
  transform: rotate(45deg);
}

.layersBox {
  padding: 20px 0;
  height: 420px;
  overflow: hidden;
  overflow-y: auto;
}

.layersWrap {
}

.MuiTab-root.Mui-selected {
  background: #f5f5f5;
}
